.contact-container {
  background-color: #13334d;
  color: #ffffff;
}
.office-holder {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1480px) {
  .office-holder {
    flex-direction: column;
  }
}
