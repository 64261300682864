.service-container-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.service-bacground {
  position: absolute;
  width: 100%;
  height: 300px;
  background-color: #dfe5eb;
}
.service-logo {
  text-align: center;
  padding-top: 1%;
  padding-bottom: 3%;
  color: #7a7a7a;
}
.service-logo-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-galery-section-container {
  background-color: #ffffff;
}
