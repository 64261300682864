.picture-section {
  position: relative;
}

.img-wraper {
  position: absolute;
  max-height: 700px;
  left: -300px;
  top: -185px;
}
.img-wraper img {
  position: absolute;
  max-width: 540px;
  border-top-left-radius: 50% 40%;
  border-top-right-radius: 50% 40%;
  overflow: hidden;
  padding-top: 20px;
}
.home-head-counter-section {
  position: relative;
  left: calc(0.5 * 100%);
  top: 200px;
}

@media (max-width: 1400px) {
  .img-wraper img {
    max-width: 450px;
    position: relative;
  }
}
@media (max-width: 1400px) {
  .picture-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-wraper {
    position: relative;
    left: 0px;
    top: 0px;
  }
  .img-wraper img {
    max-width: 400px;
    max-height: auto;
    position: relative;
  }

  .home-head-counter-section {
    display: none;
  }
}
