.nav-container {
  width: 100%;
  /* height: 50px; */
  z-index: 10;
  position: relative;
}

.nav-container-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20%;
  /* background-color: #f5f6f7; */
}
.logo-wrap {
  display: flex;
  justify-content: center; /* Center the items horizontally */
  align-items: center;
}

.nav-menu {
  display: flex;
  justify-content: center; /* Center the items horizontally */
  align-items: center;
  flex-grow: 1; /* Allow the nav-menu to take up available space */
  padding-right: 200px; /* You may adjust or remove this padding if needed */
}

.bars {
  font-size: 28px;
  background-color: inherit;
  color: #324d64;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.nav-dropdown-button {
  display: none;
}

@media (max-width: 1322px) {
  .nav-menu {
    display: none;
  }
  .logo-wrap {
    margin-left: 25%;
  }
  .nav-dropdown-button {
    display: block;
    margin-left: 35%;
    margin-bottom: 35%;
  }
}

.dropdown-container {
  position: relative;
  padding: 0;
  margin: 0;
}
.dropdown-container-inner {
  position: absolute;
  height: auto;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 400;
}

.opend-dropdown {
  height: auto;
}

.closed-dropdown {
  display: none;
}
.expand-toper {
  z-index: 400;
}
.dropdown-nav-menu {
  display: flex;
  justify-content: flex-start; /* Center the items horizontally */
  align-items: normal;
  flex-direction: column;
  flex-grow: 1; /* Allow the nav-menu to take up available space */
  background-color: white;
}
.dropdown-nav-menu-item {
  margin-top: 10px;
}

.blue-back {
  background-color: #13334d;
  color: #ffffff;
}
.white-back {
  background-color: #f5f6f7;
  color: #13334d;
}
.gray-back {
  background-color: #dfe5eb;
  color: #13334d;
}
