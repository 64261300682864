.counter-home-section-container {
  position: relative;
  height: 100%;
}
.counter-home-section-container-inner {
  display: flex;
  justify-content: space-evenly;
  position: relative;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 5%;
}

.separater {
  display: inline-block;
  width: 1px;
  height: 200px; /* Adjust the height as needed */
  background-color: #ccc; /* Gray color */
  opacity: 0.5;
}

@media (max-width: 640px) {
  .counter-home-section-container-inner {
    flex-direction: column;
    justify-content: center;
  }

  .separater {
    display: none;
  }
}
