h5 {
  color: #7a7a7a;
}
h1 {
  color: #1f1f1f;
}
.our-proces-text-section {
  max-width: 480px;
}
h3 {
  font-size: 27px;
}
