.offer-flag-spearater {
  width: 100%;
  border-bottom: 0.2px solid rgba(61, 41, 41, 0.35);
}
.offer-flag-container {
  margin: 3%;
  box-shadow: 0px 20px 30px 0px
    rgba(30.99999999999996, 30.99999999999996, 30.99999999999996, 0.11);
}
.offer-flag-inner {
  text-align: center;
  height: 500px;
  max-width: 350px;
  margin: 1% 5% 5% 2%;
  padding: 1% 2% 2% 5%;
}

.offer-flag-inner ul {
  list-style-type: none;
  text-align: left;
  padding-left: 15%;
}
.blue-background {
  background-color: #1f5079 !important;
  color: #f5f6f7;
}

.blue-background h1 {
  color: #ffffff;
}

.white-background {
  background-color: #ffffff !important;
}
