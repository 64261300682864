.counter-section-container {
  min-width: 200px;
  min-height: 110px;
  position: relative;
}
.bottom-box {
  min-width: 200px;
  min-height: 110px;
  margin-top: 15px;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-color: #184061;
}
.top-box {
  min-width: 200px;
  min-height: 110px;
  margin-left: 15px;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-color: #f08661;
  z-index: 11;
}
.top-box-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 5%;
  text-align: center;
}
.top-box-inner .align-left {
  justify-content: left;
  /* text-align: start; */
}

.top-box-inner h3 {
  margin: 0;
}
.top-box-inner h5 {
  margin: 0;
}

.top-box h3 {
  position: relative;
  color: white;
  font-weight: bold;
  font-weight: 1000;
}
.top-box h5 {
  position: relative;
  color: white;
  font-weight: bold;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    margin-top: 100px;
  }
  to {
    opacity: 1;
    margin-top: 0px;
  }
}
