/* .about-me-container {
  background-color: #f5f6f7;
} */
.logo-container {
  margin-top: 20px !important;
}
.about-me-logo-section {
  display: flex;
  align-items: center;
  justify-content: center;
}
