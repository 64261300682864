.contact-toper-form-container {
  max-width: 600px;
}
.input-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px; /* Adjust the gap between items */
}
.contact-toper-form-container {
  background-color: #163a57;
  padding: 40px;
}

.contact-toper-form-container input {
  background-color: #194366;
  border: 1px solid #1e5079;
  height: 50px;
  width: 200px;
  color: #d4dadf;
  font-size: 18px;
}

.contact-toper-form-container input[type="text"] {
  padding-left: 10px;
  color: #d4dadf;
}
.contact-toper-form-container input::placeholder {
  color: #d4dadf;
}

.contact-toper-form-container textarea {
  background-color: #194366;
  border: 1px solid #1e5079;
  height: 160px;
  width: 444px;
  margin-top: 15px;
  color: #95a8b8;
  font-size: 18px;
}

.contact-toper-form-container textarea::before {
  left: 10px;
}
.contact-toper-form-container textarea::placeholder {
  color: #d4dadf;
}
.contact-toper-form-container button {
  margin-top: 50px;
  background-color: #ffffff;
  text-align: center;
  height: 50px;
  width: 200px;
  font-size: 18px;
  border: none;
  cursor: pointer;
}
.contact-toper-form-container button:hover {
  background-color: #13334d;
  color: #ffffff;
  border: 1px solid #ffffff;
}
@media (max-width: 600px) {
  .contact-toper-form-container {
    max-width: 310px;
  }

  .contact-toper-form-container input {
    width: 100%;
  }
  .contact-toper-form-container textarea {
    width: 102%;
  }
}
