.our-services-container {
  position: relative;
  /* height: 500px;
  width: 500px; */
}
.img-wraper-our-services-container img {
  max-width: 480px;
  /* position: absolute; */

  border-top-left-radius: 50% 40%;
  border-top-right-radius: 50% 40%;
}

.our-services-counter-section {
  position: absolute;
  left: -10%;
  bottom: 5%;
}

@media (max-width: 1260px) {
  .our-services-container {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    flex-direction: column;
  }

  .our-services-container h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
@media (max-width: 660px) {
  .our-services-counter-section {
    display: none;
  }
}
