.fake-link {
  color: #ffffffe9;
  font-family: sans-serif;
  margin: 0 10px 0 0px;
  font-size: 18px;
  font-weight: 400;
}
.fake-link:hover {
  /* color: #ffff; */
  text-shadow: 0 0 0px #ffffff, 0 0 1px #ffffff;
  cursor: pointer;
}
