.footer-container {
  width: 100%;
  background-color: #0a1b29;
  color: #c2bdb9;
  padding-bottom: 2%;
}

.inner-footer-container {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer-section {
  display: flex;
  justify-content: center; /* Center the items horizontally */
  align-items: center;
}
@media (max-width: 500px) {
  .inner-footer-container {
    flex-direction: column;
    text-align: center;
  }
}
