.about-me-origin-container-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;

  padding-bottom: 50px;
}

.about-me-origin-right {
  max-width: 760px;
}
@media (max-width: 1060px) {
  .about-me-origin-container-inner {
    flex-direction: column;
  }
}
