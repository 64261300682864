.picture-slider-container {
  width: 100%;
  max-height: 100%;
}

.picture-slider-container-inner {
  display: flex;
  justify-content: center;
  max-height: inherit;
}

.picture-slider-container img:hover {
  cursor: pointer;
  filter: brightness(70%);
}
.big-picture-section {
  display: grid;
  max-height: 100%;
  max-width: 100%;
  margin-right: 1%;
  margin-left: 1%;
}

.big-picture-section .big-picture-wraper {
  position: relative;
  height: 540px;
  max-width: 100%; /* Set a fixed height for the grid items */
  overflow: hidden;
}

.big-picture-section .big-picture-wraper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.big-picture-section .big-picture-wraper div {
  position: absolute;
  background-color: white;
  height: 30px;
  display: flex;
  align-items: center;
  z-index: 1;
  bottom: 0px;
}

.small-pictures-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px; /* Adjust the gap between items */
  max-height: 100%;
  max-width: 100%;
}
.small-pictures-section .small-picture-wraper {
  position: relative;
  width: 100%;
  height: 262px; /* Set a fixed height for the grid items */
  overflow: hidden;
}
.small-pictures-section .small-picture-wraper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.small-pictures-section .small-picture-wraper div {
  position: absolute;
  background-color: white;
  height: 30px;
  display: flex;
  align-items: center;
  z-index: 1;
  bottom: 0px;
}
.hidden-img {
  display: none;
}

@media (max-width: 1400px) {
  .picture-slider-container-inner {
    flex-direction: column;
  }
  .big-picture-section .big-picture-wraper {
    height: 100%;
    max-width: 800px; /* Set a fixed height for the grid items */
  }
  .small-pictures-section {
    grid-gap: 1%;
    margin-top: 1%;
  }
  .small-pictures-section .small-picture-wraper {
    position: relative;
    width: 98%;
    height: 260px; /* Set a fixed height for the grid items */
    overflow: hidden;
  }
}
@media (max-width: 560px) {
  .small-pictures-section {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
  .small-pictures-section .small-picture-wraper {
    width: 100%;
  }

  .small-pictures-section .small-picture-wraper img {
    margin: 1%;
  }
}

/*full image*/

.full-image-container {
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5;
}
.full-image-view {
  position: absolute; /* You may use absolute for positioning within a fixed parent */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(
    -50%,
    -50%
  ); /* Center the element relative to its own dimensions */
}

.full-image-container img {
  z-index: 10;
  max-width: 100%;
  max-height: 100%;
}

.full-image-container .full-image-left {
  position: absolute;
  right: 104%;
  top: 50%;
  z-index: 30;
  padding: 10px;

  font-size: 40px;
  color: #f5f6f7;
}
.full-image-container .full-image-left:hover {
  cursor: pointer;
}

.full-image-container .full-image-right {
  position: absolute;
  left: 104%;
  top: 50%;
  z-index: 30;
  padding: 10px;

  font-size: 40px;
  color: #f5f6f7;
}

.full-image-container .full-image-right:hover {
  cursor: pointer;
  text-shadow: 0 0 0px #ffffff, 0 0 10px #ffffff;
}

.non-display {
  display: none;
  text-shadow: 0 0 0px #ffffff, 0 0 10px #ffffff;
}
