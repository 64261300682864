.what-say-section-container {
  height: 100%;
  position: relative;
  background-color: #f5f6f7;
}

.what-say-section-container-inner {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.customer-comment-section {
  display: flex;
  height: 100%;
  min-width: 1260px;
}

.what-say-section-container-inner img {
  max-width: 515px;
  max-width: 515px;
}

.customer-comment-text-section {
  max-width: 800px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: baseline;
  flex-direction: column;
  background-color: #ffffff;
  padding: 8%;
}

.customer-comment-text-section p {
  font-style: italic;
  font-size: 20px;
  min-width: 580px;
}
.customer-comment-text-section h5 {
  color: #1e5079;
  font-size: 1.5em;
}
.customer-comment-text-section span {
  font-size: 1.4em;
  margin-top: -5%;
  color: #7a7a7a;
}

@media (max-width: 1260px) {
  .what-say-section-container {
    text-align: center;
  }
  .customer-comment-section {
    flex-direction: column;
    align-items: center;

    min-width: auto;
  }
  .couple img {
    width: 100% !important;
    height: auto !important;
    clip-path: polygon(0 15%, 100% 15%, 100% 85%, 0 85%) !important;
  }

  .customer-comment-text-section p {
    min-width: auto;
  }
}
