.offer-questions-inner {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.offer-questions-container {
  margin-top: 3%;
  background-color: #f5f6f7;
  padding-top: 3%;
  padding-bottom: 3%;
}
@media (max-width: 1260px) {
  .offer-questions-inner {
    flex-direction: column;
  }
  .who-are-we-right {
    text-align: center;
  }
}
