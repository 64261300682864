.project-galery-section-container {
  height: 100%;
  position: relative;
  background-color: #13334d;
}
.project-galery-section-container-inner {
  padding: 5% 0% 5% 0%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}
