.counter-with-picture-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}
.counter-with-picture-container img {
  width: 80px;
}

.counter-with-picture-container h1 {
  margin: 10% 0 0 0;
}
.counter-with-picture-container h5 {
  margin: 10% 0 0 0;
}
.plus {
  color: #1e5079;
  font-size: 40px;
  font-weight: 900;
  padding-bottom: 30%;
}
