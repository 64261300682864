.service-presentation-outer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px; /* Adjust the gap between items */
  max-height: 100%;
  max-width: 100%;
  padding-bottom: 100px;
}
.service-presentation-outer-wraper {
  position: relative;
  width: 100%;
  height: 100%; /* Set a fixed height for the grid items */
  overflow: hidden;
  background-color: white;

  box-shadow: 0px 20px 30px 0px
    rgba(30.99999999999996, 30.99999999999996, 30.99999999999996, 0.11);
}
.service-presentation-checker {
  padding-left: 15px;
}
.a-media-icon {
  background-color: #e8edf1 !important;
  color: #1e5079 !important;
  cursor: none;
}
@media (max-width: 500px) {
  .service-presentation-outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
