.who-are-we-container-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.who-are-we-right {
  max-width: 600px;
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;
}
.who-are-we-left {
  padding-bottom: 5%;
}
.who-are-we-left img {
  max-width: 400px;
  border-top-left-radius: 50% 40%;
  border-top-right-radius: 50% 40%;

  z-index: 200;
}
.who-are-we-image {
  position: relative;
  z-index: 200;
}
.who-are-we-counter {
  position: absolute;
  width: 300px;
  right: -5%;
  bottom: -5%;
}

@media (max-width: 1060px) {
  .who-are-we-container-inner {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .who-are-we-counter {
    position: absolute;
    width: 300px;
    left: 10%;
    bottom: -5%;
  }
  .who-are-we-left img {
    max-width: 100%;
    border-top-left-radius: 50% 40%;
    border-top-right-radius: 50% 40%;
  }
}

@media (max-width: 350px) {
  .who-are-we-counter {
    display: none;
  }
}
