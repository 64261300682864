.about-me-topper-container {
  background-color: #f5f6f7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-top: 4%;
  padding-bottom: 4%;
}
.about-me-topper-container-inner h5 {
  max-width: 650px;
}
