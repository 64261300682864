body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;

  z-index: 400;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
  z-index: 500;
}
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  max-height: 80vh; /* Set maximum height for the modal */
  overflow-y: auto;

  z-index: 503; /* Set a higher z-index value than overlay */
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}
.input-section input {
  width: 90%;
  padding: 10px;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 16px;
  outline: none;
}
.contact textarea {
  width: 90%;
  height: 70px;
  padding: 10px;
  border: none;
  font-size: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  resize: vertical; /* Allow vertical resizing */
  outline: none;
  margin: 2%;
}
.remove-button,
.add-button {
  background-color: #1e5079;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
}

.remove-button:hover {
  background-color: #c82333;
}
.add-button:hover {
  background-color: #13334d;
}

.add-button {
  margin-top: 10px;
}
.subbmit-button {
  margin-top: 15px;
}
.form-container {
  padding-top: 15px;
}
