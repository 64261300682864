.head-comment-container {
  position: relative;
}
.inner-head-comment-container {
  width: 220px;
  height: 110px;
  background-color: #ffffff;
  font-weight: 500;
  position: relative;
  left: -500px;
  top: 162px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 30px;
  box-shadow: 0px 20px 30px 0px
    rgba(30.99999999999996, 30.99999999999996, 30.99999999999996, 0.11);
}
/* 
.anime-to-top {
  animation: fadeIn 2s;
}
*/
@keyframes fadeIn {
  from {
    opacity: 0;
    margin-top: 100px;
  }
  to {
    opacity: 1;
    margin-top: 0px;
  }
}
@media (max-width: 1400px) {
  .inner-head-comment-container {
    left: -100px;
    top: -100px;
  }
}
