.why-us-picture-section {
  position: relative;
  padding: 15% 0% 0% 10%;
}

.why-us-picture-section-bottom {
  display: flex;
}
.why-us-picture-section-bottom img {
  max-width: 295px;
  max-height: 300px;
  margin: 5px;
}

.why-us-picture-section-top img {
  max-width: 600px;
  max-height: 600px;
  margin: 5px;

  border-top-left-radius: 50% 50%;
  border-top-right-radius: 50% 50%;
}
@media (max-width: 600px) {
  .why-us-picture-section-bottom {
    flex-direction: column;
  }

  .why-us-picture-section-bottom img {
    max-width: 100%;
    max-height: 100%;
    margin: 0px;
  }
  .why-us-picture-section-top img {
    max-width: 100%;
    max-height: 100%;
    margin: 0px;
  }
  .why-us-picture-section {
    padding: 0;
  }
}

@media (max-width: 1260px) {
  .why-us-picture-section {
    padding: 0;
  }
}
