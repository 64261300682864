h5 {
  color: #7a7a7a;
}
h1 {
  color: #1f1f1f;
}

.home-head-text-section h1 {
  position: relative;
}

.home-head-text-section {
  max-width: 700px;
  padding-right: 10%;
}
@media (max-width: 1400px) {
  .home-head-text-section {
    max-width: 100%;

    padding-right: 0%;
  }
}
