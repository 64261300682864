.home-head-background {
  background-color: #f5f6f7;
}
.home-head-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5%;
  padding-top: 5%;
  /* margin-left: auto;
  margin-right: auto;
  max-width: 1260px; */
}

.home-head-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-head-section.left {
  margin-top: 5%;
  margin-right: 5%;
  max-width: 600px;
}

.home-head-section.right {
  margin-top: 5%;
  margin-left: 0%;
}

.home-head-bottom {
  width: 100%;
  height: 140px;
}

.home-head-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1400px) {
  .home-head-section.left {
    margin-top: 0%;
    margin-right: 0%;
    max-width: 100%;
    text-align: center;
  }
  .home-head-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .home-head-bottom {
    padding: 0% 0%;
  }
}

@media (max-width: 660px) {
  .head-comment-container {
    display: none;
  }
  .home-head-section.right {
    display: none;
  }
  .home-head-bottom {
    display: none;
  }
}
