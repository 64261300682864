.our-office-toper-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 80px;
}
.our-office-toper-image img {
  max-width: 420px;
  border-top-left-radius: 50% 50%;
  border-top-right-radius: 50% 50%;
}

.our-office-toper-inner h1 {
  color: #ffffff;
}
.our-office-toper-inner h5 {
  color: #d4dadf;
  font-weight: 400;
}
.our-office-toper-text {
  padding-left: 1%;
}
.our-office-indent {
  padding-left: 1%;
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: column;
}

.map {
  border: 0;
  width: 100%;
  height: 450px;
}
.our-office-toper-container {
  padding: 20px;
}
@media (max-width: 800px) {
  .our-office-toper-inner {
    flex-direction: column;
    text-align: center;
  }
  .map {
    border: 0;
    width: 100%;
    height: 100%;
  }
  .our-office-toper-image img {
    max-width: 320px;
  }

  .our-office-indent {
    align-items: center;
    justify-content: center;
  }
}
