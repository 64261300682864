.redirection-section-container {
  height: auto;
  position: relative;
  background-color: #153854;
}
.redirection-section-container-inner {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  justify-self: flex-start;
  flex-direction: column;
  padding: 4%;
  text-align: center;
}

.redirection-section-container-inner h1 {
  color: white;
  margin: -10px;
}
.redirection-section-container-inner h5 {
  color: #f1faff;
  max-width: 800px;
}
