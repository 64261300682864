.contact-toper-info-container {
  max-width: 600px;
}

.contact-toper-info-inner h1 {
  color: #ffffff;
}
.contact-toper-info-inner h5 {
  color: #d4dadf;
  font-weight: 400;
}

.mail-contact-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.2px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 20px;
  max-width: 500px;
}

.contact-social-media h2 {
  padding: 5% 0 1% 0;
}
.contact-holder {
  display: flex;
  align-items: center;
}
.contact-holder .icon {
  font-size: 28px;
  margin: 5px;
  cursor: pointer;
  color: #13334d;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 50%;
  width: 1em;
  height: 1em;
}
.contact-social-media-inner {
  display: flex;
  align-items: center;
  justify-content: left;
}
.contact-social-media-inner .a-media-icon {
  background-color: #13334d;
}
@media (max-width: 800px) {
  .contact-social-media {
    text-align: center;
  }
  .contact-social-media-inner {
    justify-content: center;
  }

  .contact-toper-info-inner {
    text-align: center;
  }
}
@media (max-width: 450px) {
  .mail-contact-container {
    flex-direction: column;
    text-align: center;
  }

  .contact-holder {
    flex-direction: column;
  }
}
