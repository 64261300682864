.why-us-services-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.why-us-services-present {
  display: flex;
  justify-content: space-between;
}
.service-present-inner {
  max-width: 250px;
}
.service-present-inner p {
  color: #7a7c7c;
}
@media (max-width: 500px) {
  .why-us-services-present {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
