.why-us-section-container {
  position: relative;
  height: 100%;
}
.why-us-section-container-inner {
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
}

@media (max-width: 1260px) {
  .why-us-section-container-inner {
    flex-direction: column-reverse;
  }
}
