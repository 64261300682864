.link-page {
  font-family: sans-serif;
  color: inherit;
  margin-left: 30px;
}
.custom-link {
  text-decoration: none !important;
  color: inherit;
  transition: border-bottom 0.3s;
  transition-delay: 0.1s;
  border-bottom: 0px solid #000;
  border-bottom-width: 100%;

  transition-property: padding, border;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  padding: 2px;
}
.custom-link:hover {
  border-bottom: 2px solid #13334d;
  padding-bottom: 8px;
}
.blue-back .custom-link:hover {
  border-bottom: 2px solid #ffffff;
  padding-bottom: 8px;
}
