.why-us-text-section-container {
  max-width: 600px;
  display: flex;
  justify-self: left;
}

@media (max-width: 841px) {
  .why-us-text-section-container {
    text-align: center;
  }
  .why-us-text-section-container h1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .why-us-text-section-container h5 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .why-us-header {
    text-align: center;
  }
}
