.link-page-dropdown {
  font-family: sans-serif;
  color: #13334d;
  margin-left: 30px;
  text-decoration: none;
  font-weight: bold;
}
.custom-link-dropdown {
  text-decoration: none !important;
  color: #13334d;
  transition: border-bottom 0.3s;
  transition-delay: 0.1s;
}
