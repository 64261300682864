.step-section-container {
  display: flex;
  align-items: center;
}

.step-section-left {
  margin-top: 5%;
  margin-right: 5%;
}

@media (max-width: 1260px) {
  .step-section-left {
    margin-top: 5%;
    margin-right: 1%;
    margin-left: 0%;
  }
}
@media (max-width: 890px) {
  .step-section-container {
    flex-direction: column;
  }
  .step-container {
    margin: 1.5% !important;
    width: 95%;
  }
}
