.slider {
  cursor: grab;
  overflow: hidden;
  max-width: 100%;
}
.slider-inner {
  display: flex;
}
.item img {
  pointer-events: none;
  min-width: 20rem;
  min-height: 30rem;
}
@media (max-width: 1335px) {
  .slider {
    max-width: 850px;
  }
}
@media (max-width: 850px) {
  .slider {
    max-width: 425px;
  }
}

@media (max-width: 425px) {
  .slider {
    max-width: 350px;
  }
  .item img {
    min-width: 8rem;
    min-height: 16rem;
  }
}
