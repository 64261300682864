.step-container {
  height: 310px;
  width: 370px;
  background-color: #ffffff;
  margin: 5%;
  position: relative;
}
.step-order {
  background-color: #f5f6f7;
  width: 30%;
  height: 20%;
  font-size: 30px;
  font-weight: bold;
  position: absolute;
  top: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1e5079;
}
.step-container h3 {
  padding-top: 30%;
  padding-left: 5%;
  color: #1f1f1f;
  font-weight: 900;
}

.step-container p {
  color: #7a7a7a;
  padding-left: 5%;
  font-size: 20px;
  margin-top: -20px;
}

.step-container.inverted {
  background-color: #1e5079;
}
.inverted .step-order {
  background-color: #3b678a;
  color: #ffffff;
}

.step-container.inverted h3 {
  color: #ffffff;
}
.step-container.inverted p {
  color: #d6dfe7;
}

@media (max-width: 376px) {
  .step-container {
    height: 100%;
    width: 280px;
  }
}
