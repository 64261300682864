.top-head {
  height: 35px;
  background-color: #13334d;
  z-index: 99;
}
.inner-top-head-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section-right {
  display: flex;
  justify-content: flex-end;
  max-width: 250px;
  align-items: center;
}
.devider {
  color: #ffffff;
  font-size: 30px;
  opacity: 0.3;
}

@media (max-width: 768px) {
  .top-head {
    display: none;
  }
}
