.service-present-inner {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}
.service-present-inner .a-media-icon {
  background-color: #e8edf1 !important;
  color: #1e5079 !important;
  cursor: none;
}
