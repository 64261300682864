label {
  color: black;
}
.container {
  border-radius: 5px;
}

.remove-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
}

.remove-button:hover {
  background-color: #c82333;
}

.input-group {
  margin-bottom: 10px;
}

.label {
  margin-right: 10px;
  font-weight: bold;
}

.select {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.select:focus {
  border-color: #007bff;
}

.rw-widget-container {
  width: 100%;
}

.rw-multiselect-tag {
  margin: 2px;
  padding: 4px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
}

.rw-multiselect-tag-remove {
  margin-left: 5px;
  cursor: pointer;
}

.rw-multiselect-tag-remove:hover {
  color: #0056b3;
}

.input-steep {
  display: flex;
  flex-direction: column;
}
.input-dimensions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 20%; */
}
.input-qunatity {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
