.contact-info-footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  list-style: none;
  padding: 0;
}

.contact-info-footer .fake-link {
  margin-top: 20px;
}
