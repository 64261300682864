.our-team-container-inner {
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
}
.our-team-text {
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.worker-carousel {
  display: flex;
  align-self: center;
  justify-content: space-between;
}
