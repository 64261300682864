.our-process-section-container {
  position: relative;
  height: 100%;
  background-color: #f5f6f7;
}
.our-process-section-container-inner {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
}

@media (max-width: 1260px) {
  .our-process-section-container-inner {
    flex-direction: column;
    text-align: center;

    padding: 2% 0% 2% 0%;
  }
  .step-section-left {
    margin-top: 5%;
    margin-right: 1%;
    margin-left: 0%;
  }
}
