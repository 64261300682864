.worker-container-inner {
  display: flex;
  align-self: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  max-width: 400px;
}
.worker {
  /* margin: 2%; */
  margin: 10px;
}
.worker-img img {
  max-height: 400px;
}
