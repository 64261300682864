.dev-section {
  width: 100%;
  height: 200px;
}
.sizer {
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 1260px !important;
}
.project-galery-section-container {
  background-color: #13334d;
}
