.contact-toper-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1260px) {
  .contact-toper-inner {
    flex-direction: column;
  }
}
