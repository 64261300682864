.a-media-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4em;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  font-size: 25px;
  margin: 0 0.2em 0.3em 0em;
  cursor: pointer;
}
