.quick-link {
  color: #f5f6f7;
  font-family: sans-serif;
  margin-top: 20px;
}
.quick-custom-link {
  text-decoration: none !important;
  color: #f5f6f7;
}

.quick-link:hover {
  /* color: #ffff; */
  text-shadow: 0 0 0px #ffffff, 0 0 1px #ffffff;
  cursor: pointer;
}
