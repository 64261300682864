.articles-section-container {
  height: 100%;
  position: relative;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  max-width: 100%;
}
.articles-section-container-top {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.articles-section-container-top h1 {
  margin-left: 0%;
}
.articles-section-container-bottom {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: normal;
}

@media (max-width: 1260px) {
  .articles-section-container-bottom {
    justify-content: center;
    flex-direction: column;
  }
  .articles-section-container-top {
    justify-content: center;
  }
  .articles-section-container {
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 540px) {
  .articles-section-container-top {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-bottom: 10%;
  }
}
