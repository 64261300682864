body {
  margin: 0;
  overflow-x: hidden;
}
h1 {
  font-size: 38px;
}
.wrap {
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 1140px !important;
}

h5 {
  color: #7a7a7a;
  font-size: 18px;
}
