.btn {
  background-color: #1e5079;
  color: #f5f6f7;
  border: none;
  font: inherit;
  font-weight: 900;
  height: 45px;
  width: 190px;
}
.btn-inverted {
  background-color: #f5f6f7;
  color: #1e5079;
}
.btn:hover {
  background-color: #13334d !important;
  color: #f5f6f7 !important;
  cursor: pointer;
}
