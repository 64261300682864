.service-presentation-container {
  display: flex;
  justify-self: flex-start;
}

.service-presentation-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-presentation-img img {
  height: 86px;
  width: 86px;
}

.service-presentation-text h3 {
  color: #1f1f1f;
  font-weight: 900;
}
.service-presentation-text p {
  color: #7a7a7a;
}

@media (max-width: 839px) {
  .service-presentation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .service-presentation-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
}
