.red-header {
  color: #f08661;
  font-weight: normal;
}
.our-services-text-container {
  /* display: flex; */
  justify-content: flex-start;
  padding-left: 20px;
}
.service-presentation-section {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1260px) {
  .our-services-text-container h1 {
    display: flex;
    justify-content: center;
  }

  .our-services-text-container h5 {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 839px) {
  .service-presentation-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 0px;
  }
}
@media (max-width: 490px) {
  .img-wraper-our-services-container img {
    max-width: 100%;
  }
  .our-services-header {
    text-align: center;
  }
}
