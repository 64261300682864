.media-icon {
  font-size: 18px;
  color: #f5f6f7;
}
.media-wrap {
  background-color: #ffffff21;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 1em;
  height: 1em; */
  padding: 0.4em;
  border-radius: 50%;
  margin-left: 0.3em;
}
.media-wrap:hover {
  cursor: pointer;
  background-color: #ffff;
  color: #13334d;
  cursor: pointer;
  border-radius: 50%;
}
