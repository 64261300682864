.home-head-bottom-logo-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.6;
}
.home-head-bottom-logo-section .logo-container {
  margin-right: 20px;
  margin-top: 70px;
}

@media (max-width: 640px) {
  .home-head-bottom-logo-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }
}
@media (max-width: 460px) {
  .home-head-bottom-logo-section {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
  }
}
