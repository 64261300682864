.offer-flag-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.offer-header-inner {
  text-align: center;
}
.offer-header-container {
  padding-top: 5%;
  background-color: #ffffff;
  margin-top: -2%;
}
@media (max-width: 1260px) {
  .offer-flag-list {
    flex-direction: column;
  }
}
