.our-services-section-container {
  height: 100%;
  position: relative;
}

.our-services-section-container-inner {
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}

@media (max-width: 1260px) {
  .our-services-section-container-inner {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    flex-direction: column;
  }
}
