.article-container {
  width: 400px;
}

.article-container img {
  max-width: 353px;
}

.read-more {
  color: #1e50a4;
  font-weight: bold;
  cursor: pointer;
}

.opend-text {
  height: auto;
}

.closed-text {
  display: none;
  /* height: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
}

.article-container h3 {
  color: #1f1f1f;
  font-weight: 900;
  cursor: pointer;
  max-width: 353px;
  height: 105px;
}
.article-container {
  color: #7a7a7a;
  font-size: 20px;
}
.article-container p {
  color: #7a7a7a;
  font-size: 20px;
  margin-top: -20px;
}
@media (max-width: 1260px) {
  .article-container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .article-container img {
    max-width: 80%;
  }
}
